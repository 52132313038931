<template>
  <basic-form-group
    :id="id"
    :label-is-up="labelIsUp"
    :label="$attrs.label"
    :required="$attrs.required"
    :horizontal="$attrs.horizontal"
    :columns="$attrs.columns"
    :margin="$attrs.margin"
    :small="$attrs.small"
    :infotext="$attrs.infotext"
    :align-label-center="$attrs.alignLabelCenter"
  >
    <template slot="label">
      <slot name="label" />
    </template>
    <template slot="input">
      <div class="input-group">
        <input
          :id="id"
          v-model.lazy="date"
          v-validate="datepickerValidation"
          v-bind="$attrs"
          :class="{'is-invalid': veeFields[veeField] && veeFields[veeField].validated && veeFields[veeField].invalid, 'form-control-sm': sm}"
          :name="veeField"
          :data-vv-as="name"
          class="form-control"
          type="text"
          :disabled="$attrs.disabled"
        >
        <div
          v-if="!$attrs.disabled"
          class="input-group-append"
        >
          <transition name="slide-fade">
            <div
              v-if="isOpen"
              class="calendar-wrapper left"
            >
              <div class="card mt-1 shadow-sm">
                <div class="card-body">
                  <datepicker
                    :id="'datepicker-'+id"
                    v-model="datepickerDate"
                    :language="de"
                    class="datepicker"
                    :class="{ 'form-control-sm': sm }"
                    inline
                    :disabled-dates="disabledDates"
                    :name="'datepicker-'+veeField"
                    @selected="isOpen = false"
                  />
                </div>
              </div>
            </div>
          </transition>
          <button
            type="button"
            class="btn btn-primary"
            :class="{ 'btn-sm': sm }"
            tabindex="-1"
            @click="isOpen = !isOpen"
            @blur="isOpen = false"
          >
            <i class="fas fa-calendar-alt" />
          </button>
        </div>
      </div>
      <validation-feedback
        :name="veeField"
        :custom-error-message="customErrorMessage"
      />
    </template>
  </basic-form-group>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import { de } from 'vuejs-datepicker/dist/locale'
import basicComponentMixin from './basic-component-mixin.js'
import moment from 'moment'

export default {
  name: 'BasicDatepicker',
  components: {
    Datepicker
  },
  mixins: [basicComponentMixin],
  props: {
    disabledDates: {
      type: Object,
      default: () => ({}),
      required: false
    },
    format: {
      type: String,
      required: false,
      default: 'DD.MM.YYYY'
    },
    sm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false,
      de
    }
  },
  computed: {
    date: {
      get () {
        if (!this.model) return this.model
        return moment(this.model).format(this.format)
      },
      set (dateStr) {
        this.model = this.parseDate(dateStr)
      }
    },
    datepickerDate: {
      get () {
        return this.model
      },
      set (date) {
        this.model = moment(date).startOf('day').toDate()
      }
    },
    datepickerValidation () {
      return {
        'disabled-dates': { disabledDates: this.disabledDates, format: this.format },
        ...this.getValidation
      }
    }
  },
  methods: {
    parseDate (dateStr) {
      const parsed = moment(dateStr, this.format)
      if (parsed.isValid()) {
        return parsed.toDate()
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.calendar-wrapper {
  z-index: 1000;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  &.left{
    right: 0;
  }
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 200ms ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(90%);
  opacity: 0;
}
.datepicker ::v-deep {
  .vdp-datepicker__calendar {
    border: none
  }
  .cell.day:not(.blank) {
    &.today{
      background-color: $gray-2;
    }
    &.selected{
      background-color: $primary;
      color: white;
    }
    border-radius: 50px;
    &:hover{
      outline: none;
      border: none;
      -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.53);
      -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.53);
      box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.53);
    }
  }
}
</style>
